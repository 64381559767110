<template>
  <b-sidebar
    id="merchant-sidebar"
    :visible="isMerchantSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-merchant-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Merchant Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-form class="p-2">

          <b-button
            v-if="!merchantInfo.logoUrl"
            variant="primary"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          >
            Upload LOGO
          </b-button>

          <b-img
            v-if="merchantInfo.logoUrl"
            thumbnail
            fluid
            center
            :src="merchantInfo.logoUrl"
            alt="Image"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          />
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            accept="image/*"
            @change="inputImageRenderer"
          >

          <!-- Merchant NO. -->
          <b-form-group
            label="NO."
            label-for="merchant-no"
          >
            <b-form-input
              id="merchant-no"
              v-model="merchantInfo.merchantNo"
              autofocus
              trim
              disabled
            />
          </b-form-group>

          <!-- Merchant Name -->
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="merchant-name"
            >
              <b-form-input
                id="merchant-name"
                v-model="merchantInfo.merchantName"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <b-form-group
            label="Type"
            label-for="merchant-type"
          >
            <v-select
              v-model="merchantInfo.type"
              :options="typeOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="merchant-type"
            />
          </b-form-group>

          <!-- Tag -->
          <b-form-group
            label="Tag"
            label-for="merchant-tag"
          >
            <v-select
              v-model="merchantInfo.tag"
              :options="tagOptions"
              :searchable="false"
              multiple
              input-id="merchant-tag"
            />
          </b-form-group>

          <!-- Contact Name -->
          <validation-provider
            #default="{ errors }"
            name="Contact Name"
            rules="required"
          >
            <b-form-group
              label="Contact Name"
              label-for="merchant-contact-name"
            >
              <b-form-input
                id="merchant-contact-name"
                v-model="merchantInfo.merchantContactName"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact Email -->
          <validation-provider
            #default="{ errors }"
            name="Contact Email"
            rules="required|email"
          >
            <b-form-group
              label="Contact Email"
              label-for="merchant-contact-email"
            >
              <b-form-input
                id="merchant-contact-email"
                v-model="merchantInfo.merchantContactEmail"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact Phone -->
          <validation-provider
            #default="{ errors }"
            name="Contact Phone"
            rules="required"
          >
            <b-form-group
              label="Contact Phone"
              label-for="merchant-contact-phone"
            >
              <b-form-input
                id="merchant-contact-phone"
                v-model="merchantInfo.merchantContactPhone"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <b-form-group
            label="Country"
            label-for="merchant-country"
          >
            <v-select
              v-model="merchantInfo.merchantCountry"
              :options="countryOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="true"
              input-id="merchant-country"
            />
          </b-form-group>

          <!-- Location -->
          <b-form-group
            label="Location"
            label-for="merchant-location"
          >
            <b-form-input
              id="merchant-location"
              v-model="merchantInfo.merchantLocation"
              autofocus
              trim
            />
          </b-form-group>

          <!-- Status -->
          <b-form-group
            label="Status"
            label-for="merchant-status"
          >
            <v-select
              v-model="merchantInfo.status"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="merchant-status"
            />
          </b-form-group>

          <el-divider
            class="mt-3 mb-2"
          />

          <!-- IPN Status -->
          <b-form-group
            label="IPN Status"
            label-for="ipn-status"
          >
            <v-select
              v-model="merchantConfig.ipnStatus"
              input-id="ipn-status"
              :options="ipnStatusOptions"
              :searchable="false"
              :reduce="val => val.value"
              multiple
              :close-on-select="false"
            />
          </b-form-group>

          <el-divider
            class="mt-3 mb-2"
          />

          <!-- Risk Management Service Provider -->
          <b-form-group
            label="Risk Management Service Provider"
            label-for="rmsp"
          >
            <v-select
              v-model="merchantConfig.rmsp"
              input-id="rmsp"
              :options="rmspOptions"
              :clearable="false"
              :searchable="false"
              :reduce="val => val.value"
            />
          </b-form-group>
          <div v-if="merchantConfig.rmsp === 'Forter'">
            <b-form-group
              v-if="forterSiteId !== ''"
              label="Forter Site ID"
              label-for="foter-site-id"
            >
              <b-form-input
                id="foter-site-id"
                v-model="forterSiteId"
                :disabled="true"
                autofocus
                trim
              />
            </b-form-group>
            <div v-else>
              <b-form-group
                label="Forter Site Name"
                label-for="foter-site-name"
              >
                <b-form-input
                  id="foter-site-name"
                  v-model="forterSiteName"
                  autofocus
                  trim
                />
              </b-form-group>
              <b-form-group
                label="MCC"
                label-for="foter-site-mcc"
              >
                <b-form-input
                  id="foter-site-mcc"
                  v-model="forterSiteMcc"
                  trim
                />
                <b-button
                  variant="primary"
                  type="submit"
                  class="mt-2"
                  block
                  @click.prevent="createForterMerchant"
                >
                  Create Forter Merchant
                </b-button>
              </b-form-group>
            </div>
          </div>

          <div v-if="showConfigs">
            <el-divider
              class="mt-3 mb-2"
            />

            <!-- Balance Visible -->
            <b-form-group
              label="Balance Visible"
              label-for="balance-visible"
            >
              <v-select
                v-model="merchantConfig.balanceVisible"
                :options="booleanOptions"
                :clearable="false"
                :searchable="false"
                input-id="balance-visible"
              />
            </b-form-group>

            <!-- Settlement Method -->
            <b-form-group
              label="Settlement Method"
              label-for="settlement-method"
            >
              <v-select
                v-model="merchantConfig.settlementMethod"
                :options="settlementMethodOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
                input-id="settlement-method"
              />
            </b-form-group>

            <!-- APP Generates Bills Independently -->
            <b-form-group
              label="APP Generates Bills Independently"
              label-for="settlement-gen-by-app"
            >
              <v-select
                v-model="merchantConfig.settlementGenByApp"
                :options="booleanOptions"
                :clearable="false"
                :searchable="false"
                input-id="settlement-gen-by-app"
              />
            </b-form-group>

            <!-- Automatic Transfer to Payout Balance -->
            <b-form-group
              label="Automatic Transfer to Payout Balance"
              label-for="settlement-transfer-to-payout-balance"
            >
              <v-select
                v-model="merchantConfig.settlementTransferToPayoutBalance"
                :options="booleanOptions"
                :clearable="false"
                :searchable="false"
                input-id="settlement-transfer-to-payout-balance"
              />
            </b-form-group>

            <!-- Settlement Exchange Rate -->
            <b-form-group
              label="Settlement Exchange Rate"
              label-for="settlement-exchange-rate"
            >
              <v-select
                v-model="merchantConfig.settlementExchangeRate"
                :options="settlementExchangeRateOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
                input-id="settlement-exchange-rate"
              />
            </b-form-group>
            <b-form-group
              label="Transfer Virtual Account Create"
              label-for="transfer-virtual-account"
            >
              <v-select
                v-model="merchantConfig.transferVirtualAccount"
                :options="booleanOptions"
                :clearable="false"
                :searchable="false"
                input-id="transfer-virtual-account"
              />
            </b-form-group>

            <!-- Settlement Currency -->
            <b-form-group
              label="Settlement Currency"
              label-for="settlement-currency"
            >
              <v-select
                v-model="merchantConfig.settlementCurrency"
                :options="currencyOptions"
                :clearable="false"
                input-id="settlement-currency"
              />
            </b-form-group>

            <div v-if="merchantConfig.settlementMethod === 'A'">
              <!-- Settlement Reports Generation Cycle -->
              <b-form-group
                label="Settlement Reports Generation Cycle"
                label-for="settlement-cycle-type"
              >
                <v-select
                  v-model="merchantConfig.settlementCycleType"
                  :options="settlementCycleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :searchable="false"
                  input-id="settlement-cycle-type"
                />
              </b-form-group>

              <b-input-group
                v-if="merchantConfig.settlementCycleType === 'D'"
                prepend="Every"
                append="Day"
              >
                <b-form-input
                  v-model="merchantConfig.settlementCycleInterval"
                  type="number"
                  min="0"
                  step="1"
                  trim
                  class="text-center-align"
                />
              </b-input-group>

              <b-row
                v-if="merchantConfig.settlementCycleType === 'W'"
                class="mt-1"
              >
                <b-col
                  cols="12"
                  md="5"
                >
                  <v-select
                    v-model="merchantConfig.settlementCycleValue"
                    :options="dayOfWeekOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :searchable="false"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="7"
                >
                  <b-input-group
                    prepend="Every"
                    append="Week"
                  >
                    <b-form-input
                      v-model="merchantConfig.settlementCycleInterval"
                      type="number"
                      min="0"
                      step="1"
                      trim
                      class="text-center-align"
                    />
                  </b-input-group>
                </b-col>
              </b-row>

              <b-row
                v-if="merchantConfig.settlementCycleType === 'M'"
                class="mt-1"
              >
                <b-col
                  cols="12"
                  md="5"
                >
                  <v-select
                    v-model="merchantConfig.settlementCycleValue"
                    :options="dayOfMonthOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :searchable="false"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="7"
                >
                  <b-input-group
                    prepend="Every"
                    append="Month"
                  >
                    <b-form-input
                      v-model="merchantConfig.settlementCycleInterval"
                      type="number"
                      min="0"
                      step="1"
                      trim
                      class="text-center-align"
                    />
                  </b-input-group>
                </b-col>
              </b-row>

              <b-row
                v-if="merchantConfig.settlementCycleType === 'A'"
                class="mt-1"
              >
                <b-col
                  cols="12"
                  md="5"
                >
                  <v-select
                    v-model="merchantConfig.settlementCycleAmountType"
                    :options="cycleAmountTypeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :searchable="false"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="7"
                >
                  <b-input-group
                    prepend="Over"
                  >
                    <b-form-input
                      v-model="merchantConfig.settlementCycleInterval"
                      type="number"
                      min="0"
                      step="1"
                      trim
                      class="text-center-align"
                    />
                    <b-input-group-append>
                      <b-button variant="outline-secondary">
                        {{ merchantConfig.settlementCurrency }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <el-divider class="mt-3 mb-3" />

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isSubmitDisabled"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
      <b-overlay
        :show="loading"
        no-wrap
      />
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BImg, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BForm,
  BInputGroup, BRow, BCol, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import vSelect from 'vue-select'
import countries from '@/assets/json/countries.json'
import { showToast, showAlert } from '@/libs/tool'
import useMerchantsJs from './merchants'

const {
  create,
  update,
  fetchMerchantConfigs,
  fetchCommonOptions,
  fetchMerchantInfo,
  forterSiteCreation,
} = useMerchantsJs()

export default {
  components: {
    BSidebar,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BRow,
    BCol,
    BInputGroupAppend,
    vSelect,

    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  model: {
    prop: 'isMerchantSidebarActive',
    event: 'update:is-merchant-sidebar-active',
  },
  props: {
    isMerchantSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countries,
      loading: false,
      merchantInfo: {
        logoUrl: '',
        merchantNo: '',
        merchantName: '',
        type: 0,
        tag: '',
        merchantContactName: '',
        merchantContactEmail: '',
        merchantContactPhone: '',
        location: '',
        status: 0,
        merchantCountry: '',
        forterSite: '',
      },
      countryOptions: [],
      showConfigs: true,
      merchantConfig: {
        ipnStatus: '',
        balanceVisible: 'No',
        settlementMethod: 'M',
        settlementGenByApp: 'Yes',
        settlementTransferToPayoutBalance: 'No',
        settlementExchangeRate: 'Bank',
        settlementCurrency: 'USD',
        settlementCycleType: 'M',
        settlementCycleValue: '1',
        settlementCycleAmountType: '0',
        settlementCycleInterval: '',
        transferVirtualAccount: 'No',
        rmsp: '',
      },
      error: '',
      userData: {
        fullName: '',
        username: '',
        email: '',
        contact: '',
        company: '',
        country: '',
        role: '',
        currentPlan: '',
      },

      typeOptions: [
        { label: 'Other', value: 0 },
        { label: 'Game', value: 1 },
        { label: 'E-Commer', value: 2 },
        { label: 'PSP', value: 3 },
      ],

      tagOptions: [
        'ExtremelyHighRisk',
        'HighRisk',
        'MediumRisk',
        'LowRisk',
        'V1M',
        'V2M',
      ],

      statusOptions: [
        { label: 'Disable', value: '0' },
        { label: 'Enable', value: '1' },
      ],

      booleanOptions: [
        'Yes',
        'No',
      ],

      settlementMethodOptions: [
        { label: 'Automatic', value: 'A' },
        { label: 'Semi Automatic', value: 'SA' },
        { label: 'Manual', value: 'M' },
      ],

      settlementExchangeRateOptions: [
        { label: 'By Transaction', value: 'Transaction' },
        { label: 'By Settlement', value: 'Settlement' },
        { label: 'By Bank', value: 'Bank' },
      ],

      reserveTypeOptions: [
        { label: 'Off', value: 0 },
        { label: 'Rolling', value: 1 },
        { label: 'Capacity', value: 2 },
        { label: 'One-Off', value: 3 },
      ],

      currencyOptions: [],

      settlementCycleOptions: [
        { label: 'By Day', value: 'D' },
        { label: 'By Week', value: 'W' },
        { label: 'By Half-Monthly', value: 'HM' },
        { label: 'By Month', value: 'M' },
        { label: 'By Settleable Amount', value: 'A' },
      ],

      rmspOptions: [
        { label: 'None', value: '' },
        // { label: 'TongDun', value: 'TongDun' },
        { label: 'Forter', value: 'Forter' },
      ],

      dayOfMonthOptions: [
        { label: '1st', value: '1' },
        { label: '2nd', value: '2' },
        { label: '3rd', value: '3' },
        { label: '4th', value: '4' },
        { label: '5th', value: '5' },
        { label: '6th', value: '6' },
        { label: '7th', value: '7' },
        { label: '8th', value: '8' },
        { label: '9th', value: '9' },
        { label: '10th', value: '10' },
        { label: '11th', value: '11' },
        { label: '12th', value: '12' },
        { label: '13th', value: '13' },
        { label: '14th', value: '14' },
        { label: '15th', value: '15' },
        { label: '16th', value: '16' },
        { label: '17th', value: '17' },
        { label: '18th', value: '18' },
        { label: '19th', value: '19' },
        { label: '20th', value: '20' },
        { label: '21st', value: '21' },
        { label: '22nd', value: '22' },
        { label: '23rd', value: '23' },
        { label: '24th', value: '24' },
        { label: '25th', value: '25' },
        { label: '26th', value: '26' },
        { label: '27th', value: '27' },
        { label: '28th', value: '28' },
      ],

      dayOfWeekOptions: [
        { label: 'Monday', value: '1' },
        { label: 'Tuesday', value: '2' },
        { label: 'Wednesday', value: '3' },
        { label: 'Thursday', value: '4' },
        { label: 'Friday', value: '5' },
        { label: 'Saturday', value: '6' },
        { label: 'Sunday', value: '7' },
      ],

      cycleAmountTypeOptions: [
        { label: 'Of Order', value: '0' },
        { label: 'Of Settle', value: '1' },
      ],

      ipnStatusOptions: [
        { label: 'Processing', value: '18000' },
        { label: 'Success', value: '20000' },
        { label: 'Cancelled', value: '25000' },
        { label: 'RiskControl', value: '27000' },
        { label: 'Dispute', value: '30000' },
        { label: 'Refused', value: '21000' },
        { label: 'Refunded', value: '22000' },
        { label: 'PartialRefund', value: '22001' },
        { label: 'RefundRefused', value: '22003' },
        { label: 'ChargeBack', value: '23000' },
        { label: 'ChargebackReversed', value: '23001' },
      ],

      forterSiteId: '',
      forterSiteName: '',
      forterSiteMcc: '',

      required,
      alphaNum,
      email,
    }
  },
  computed: {
    isSubmitDisabled() {
      return this.merchantInfo.type === undefined || this.merchantInfo.status === undefined
    },
  },
  watch: {
    isMerchantSidebarActive(val) {
      if (!val) {
        return
      }
      // 临时方案，难登大雅之堂
      if (this.data.merchant_no !== '') {
        this.loading = true
        fetchMerchantInfo(this, this.data.merchant_no, info => {
          this.initMerchantInfo(info)
          fetchMerchantConfigs(this, this.data.merchant_no, configs => {
            this.initMerchantConfigs(configs)
            this.loading = false
          })
          this.showConfigs = true
        })
      } else {
        const info = {}
        this.initMerchantInfo(info)
        const configs = []
        this.initMerchantConfigs(configs)
        this.showConfigs = true
      }
      fetchCommonOptions(
        options => {
          const { currencies } = options
          currencies.forEach(item => {
            this.currencyOptions.push(item.code)
          })
          this.currencyOptions.push('%')
        },
        fail => {
          showToast(this, 'Warning', `Fetch options fail with ${fail}`)
        },
      )
    },
  },
  mounted() {
    const merchantCountryList = []
    this.countries.forEach(item => {
      const merchantCountry = { label: item.name.official, value: item.cca3 }
      merchantCountryList.push(merchantCountry)
    })
    this.countryOptions = merchantCountryList
  },
  methods: {
    initMerchantInfo(info) {
      this.merchantInfo = {
        logoUrl: info.logo_url,
        merchantNo: info.merchant_no,
        merchantName: info.merchant_name,
        type: info.type,
        tag: info.tag ? info.tag.split(',') : '',
        merchantContactName: info.contact,
        merchantContactEmail: info.contact_email,
        merchantContactPhone: info.phone,
        merchantLocation: info.location,
        status: info.status,
        merchantCountry: info.country,
        forterSite: info.forter_site,
      }
      this.showConfigs = false
    },
    initMerchantConfigs(configs) {
      this.merchantConfig = {
        ipnStatus: '',
        balanceVisible: 'No',
        settlementMethod: 'M',
        settlementGenByApp: 'Yes',
        settlementTransferToPayoutBalance: 'No',
        settlementExchangeRate: 'Bank',
        settlementCurrency: 'USD',
        settlementCycleType: 'M',
        settlementCycleValue: '1',
        settlementCycleAmountType: '0',
        settlementCycleInterval: '',
        rmsp: '',
        transferVirtualAccount: 'No',
      }
      this.forterSiteId = ''
      configs.forEach(config => {
        if (!Object.prototype.hasOwnProperty.call(config, 'key')) {
          return
        }
        if (!Object.prototype.hasOwnProperty.call(config, 'value')) {
          return
        }
        const { key, value } = config
        if (key === 'balance_visible') {
          this.merchantConfig.balanceVisible = value
        }
        if (key === 'settlement_method') {
          this.merchantConfig.settlementMethod = value
        }
        if (key === 'settlement_gen_by_app') {
          this.merchantConfig.settlementGenByApp = value
        }
        if (key === 'settlement_to_payout_balance') {
          this.merchantConfig.settlementTransferToPayoutBalance = value
        }
        if (key === 'settlement_exchange_rate') {
          this.merchantConfig.settlementExchangeRate = value
        }
        if (key === 'settlement_currency') {
          this.merchantConfig.settlementCurrency = value
        }
        if (key === 'ipn_status_filter') {
          this.merchantConfig.ipnStatus = value ? value.split(',') : ''
        }
        if (key === 'rmsp') {
          this.merchantConfig.rmsp = value
        }
        if (key === 'transfer_account_create') {
          this.merchantConfig.transferVirtualAccount = value
        }
        this.forterSiteId = this.merchantInfo.forterSite
        if (key === 'settlement_report_cycle') {
          const srCycleArray = value.split(',')
          const [param0, param1, param2] = srCycleArray
          this.merchantConfig.settlementCycleType = param0
          if (this.merchantConfig.settlementCycleType === 'A') {
            this.merchantConfig.settlementCycleInterval = param1
            this.merchantConfig.settlementCycleAmountType = param2
          } else if (this.merchantConfig.settlementCycleType === 'D') {
            this.merchantConfig.settlementCycleInterval = param1
          } else if (this.merchantConfig.settlementCycleType === 'W' || this.merchantConfig.settlementCycleType === 'M') {
            this.merchantConfig.settlementCycleInterval = param1
            this.merchantConfig.settlementCycleValue = param2
          }
        }
      })
    },
    initValues() {
      this.merchantInfo = {
        logoUrl: '',
        merchantNo: '',
        merchantName: '',
        type: 0,
        tag: '',
        merchantContactName: '',
        merchantContactEmail: '',
        merchantContactPhone: '',
        merchantLocation: '',
        merchantCountry: '',
        status: '0',
      }
    },
    inputImageRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size / 1024 > 64) {
          this.$bvToast.toast('File is too large, Please control the file size within 64k.', {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'warning',
            toaster: 'b-toaster-top-center',
            solid: true,
          })
          return
        }
        const reader = new FileReader()
        reader.onload = e => {
          this.merchantInfo.logoUrl = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.updateItem()
            }
          })
        }
      })
    },
    updateItem() {
      // 编辑config配置list
      const configs = []
      const statusList = this.merchantConfig.ipnStatus === '' ? '' : this.merchantConfig.ipnStatus.join(',')
      const ipnStatusList = { key: 'ipn_status_filter', value: statusList }
      const sMethod = { key: 'settlement_method', value: this.merchantConfig.settlementMethod }
      const sGenByApp = { key: 'settlement_gen_by_app', value: this.merchantConfig.settlementGenByApp }
      const sTransferToPayoutBalance = { key: 'settlement_to_payout_balance', value: this.merchantConfig.settlementTransferToPayoutBalance }
      const sExchangeRate = { key: 'settlement_exchange_rate', value: this.merchantConfig.settlementExchangeRate }
      const sCurrency = { key: 'settlement_currency', value: this.merchantConfig.settlementCurrency }
      const sBalanceVisible = { key: 'balance_visible', value: this.merchantConfig.balanceVisible }
      const transferAccountCreate = { key: 'transfer_account_create', value: this.merchantConfig.transferVirtualAccount }
      if (this.merchantConfig.settlementMethod === 'A') {
        let srCycle = ''
        if (this.merchantConfig.settlementCycleType === 'A') {
          srCycle = { key: 'settlement_report_cycle', value: `${this.merchantConfig.settlementCycleType},${this.merchantConfig.settlementCycleInterval},${this.merchantConfig.settlementCycleAmountType},${this.merchantConfig.settlementCurrency}` }
        } else if (this.merchantConfig.settlementCycleType === 'D') {
          srCycle = { key: 'settlement_report_cycle', value: `${this.merchantConfig.settlementCycleType},${this.merchantConfig.settlementCycleInterval}` }
        } else if (this.merchantConfig.settlementCycleType === 'W' || this.merchantConfig.settlementCycleType === 'M') {
          srCycle = { key: 'settlement_report_cycle', value: `${this.merchantConfig.settlementCycleType},${this.merchantConfig.settlementCycleInterval},${this.merchantConfig.settlementCycleValue}` }
        } else if (this.merchantConfig.settlementCycleType === 'HM') {
          srCycle = { key: 'settlement_report_cycle', value: this.merchantConfig.settlementCycleType }
        }
        configs.push(srCycle)
      }
      if (this.merchantConfig.rmsp !== '') {
        const rmsp = { key: 'rmsp', value: this.merchantConfig.rmsp }
        configs.push(rmsp)
      }
      configs.push(ipnStatusList)
      configs.push(sBalanceVisible)
      configs.push(sMethod)
      configs.push(sGenByApp)
      configs.push(sTransferToPayoutBalance)
      configs.push(sExchangeRate)
      configs.push(sCurrency)
      configs.push(transferAccountCreate)

      const params = {
        merchant_no: this.merchantInfo.merchantNo,
        merchant_name: this.merchantInfo.merchantName,
        type: this.merchantInfo.type,
        tag: this.merchantInfo.tag === '' ? '' : this.merchantInfo.tag.join(','),
        contact: this.merchantInfo.merchantContactName,
        contact_email: this.merchantInfo.merchantContactEmail,
        phone: this.merchantInfo.merchantContactPhone,
        location: this.merchantInfo.merchantLocation,
        country: this.merchantInfo.merchantCountry,
        logo_url: this.merchantInfo.logoUrl,
        status: this.merchantInfo.status,
        configs,
      }

      if (this.merchantInfo.merchantNo && this.merchantInfo.merchantNo !== '') {
        update(
          params,
          success => {
            showAlert(this, 'success', 'Done!', `NO. is ${success.merchant_no}`)
            this.$emit('update:is-merchant-sidebar-active', false)
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      } else {
        delete params.merchant_no
        create(
          params,
          success => {
            showAlert(this, 'success', 'Done!', `NO. is ${success.merchant_no}`)
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      }
      this.initValues()
    },
    createForterMerchant() {
      const params = {
        merchantNo: this.merchantInfo.merchantNo,
        siteName: this.forterSiteName,
        mcc: this.forterSiteMcc,
      }
      forterSiteCreation(
        params,
        rmsp => {
          this.forterSiteId = `${rmsp.site_id}:${rmsp.site_name}`
          showAlert(this, 'success', 'Done!', 'Registration Success')
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#merchant-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  .text-center-align {
    text-align: center;
  }
}
</style>
