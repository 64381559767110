<template>
  <div>
    <b-row>
      <!-- Total Amount -->
      <b-col cols="12" md="2">
        <b-card
          title="Total Amount"
          sub-title="INCLUDING processing fee"
        >
          <table
            v-for="item in totalAmount"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Net Income -->
      <b-col cols="12" md="2">
        <b-card
          title="Net Income"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in netIncome"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Balance -->
      <b-col cols="12" md="2">
        <b-card
          title="Balance"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in balance"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Settlement Amount -->
      <b-col v-if="settlementAmount" cols="12" md="2">
        <b-card
          title="Settlement"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in settlementAmount"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Payout Amount -->
      <b-col v-if="payoutAmount" cols="12" md="2">
        <b-card
          title="Payout"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in payoutAmount"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Frozen -->
      <b-col cols="12" md="2">
        <b-card
          title="Frozen"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in frozen"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Reserve Fund -->
      <b-col cols="12" md="2">
        <b-card
          title="Reserve"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in reserve"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
    </b-row>

    <app-collapse class="mb-2">

      <app-collapse-item v-if="$can('write', 'Merchants.List')" class="mb-2" title="Update Assets">
        <b-card>
          <b-row>
            <b-col>
              <v-select
                v-model="modifiedCurrency"
                input-id="target-currency"
                :options="sourceCurrency"
                :clearable="false"
                :searchable="false"
                top
                placeholder="Currency"
              />
            </b-col>
            <b-col>
              <b-form-input v-model="modified.total" placeholder="Total" type="number" trim/>
            </b-col>
            <b-col>
              <b-form-input v-model="modified.netIncome" placeholder="Net Income" type="number" trim/>
            </b-col>
            <b-col>
              <b-form-input v-model="modified.balance" placeholder="Balance" type="number" trim/>
            </b-col>
            <b-col>
              <b-form-input v-model="modified.settled" placeholder="Settled" type="number" trim/>
            </b-col>
            <b-col>
              <b-form-input v-model="modified.payout" placeholder="Payout" type="number" trim/>
            </b-col>
            <b-col>
              <b-form-input v-model="modified.frozen" placeholder="Frozen" type="number" trim/>
            </b-col>
            <b-col>
              <b-form-input v-model="modified.reserve" placeholder="Reserve" type="number" trim/>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="12" md="3">
              <b-form-input v-model="verificationCode" placeholder="Verification Code" trim/>
            </b-col>
            <b-col>
              <b-button
                variant="primary"
                :disabled="!modifiedCurrency || modifiedCurrency === '' || verificationCode === ''"
                @click="modifyAssetDataManually"
              >
                Confirm
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </app-collapse-item>

      <!-- <app-collapse-item v-if="$can('write', 'Merchants.List')" class="mb-2" title="Reserve Fund">
        <b-card>
          <b-row>
            <b-col>
              <v-select
                v-model="modifiedCurrency"
                input-id="target-currency"
                :options="currencyOptions"
                :clearable="false"
                top
              />
            </b-col>
            <b-col>
              <v-select
                v-model="reserveType"
                input-id="reserve-type"
                :options="reserveTypeOptions"
                :clearable="false"
                :searchable="false"
                :reduce="val => val.value"
              />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col>
              <b-button
                variant="primary"
                :disabled="!modifiedCurrency || modifiedCurrency === ''"
                @click="modifyAssetDataManually"
              >
                Confirm
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </app-collapse-item> -->

      <app-collapse-item title="Activity Timeline">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-form-datepicker
                v-model="startDate"
                menu-class="w-100"
                calendar-width="100%"
                :max="max"
                placeholder="Start Date"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-form-datepicker
                v-model="endDate"
                menu-class="w-100"
                calendar-width="100%"
                :min="min"
                :max="max"
                :readonly="readonly"
                placeholder="End Date"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-table-simple
          hover
          bordered
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Time</b-th>
              <b-th>Desc</b-th>
              <b-th>Total Amount</b-th>
              <b-th>Net Income</b-th>
              <b-th>Balance</b-th>
              <b-th>Settlement</b-th>
              <b-th>Payout</b-th>
              <b-th>Frozen</b-th>
              <b-th>Reserve</b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-for="(item, index) in timelineData" :key="index">
            <b-tr>
              <b-th rowspan="2">
                {{ item.time }}
              </b-th>
              <b-th rowspan="2">
                {{ item.detail.desc }}
              </b-th>
              <b-td v-if="item.detail.after" variant="primary">
                {{ item.detail.after.currency }} {{ item.detail.after.total_revenue || '0' }}
              </b-td>
              <b-td v-else variant="primary">
                N/A
              </b-td>
              <b-td v-if="item.detail.after" variant="primary">
                {{ item.detail.after.currency }} {{ item.detail.after.net_income || '0' }}
              </b-td>
              <b-td v-else variant="primary">
                N/A
              </b-td>
              <b-td v-if="item.detail.after" variant="primary">
                {{ item.detail.after.currency }} {{ item.detail.after.balance || '0' }}
              </b-td>
              <b-td v-else variant="primary">
                N/A
              </b-td>
              <b-td v-if="item.detail.after" variant="primary">
                {{ item.detail.after.currency }} {{ item.detail.after.settled || '0' }}
              </b-td>
              <b-td v-else variant="primary">
                N/A
              </b-td>
              <b-td v-if="item.detail.after" variant="primary">
                {{ item.detail.after.currency }} {{ item.detail.after.payout || '0' }}
              </b-td>
              <b-td v-else variant="primary">
                N/A
              </b-td>
              <b-td v-if="item.detail.after" variant="primary">
                {{ item.detail.after.currency }} {{ item.detail.after.frozen || '0' }}
              </b-td>
              <b-td v-else variant="primary">
                N/A
              </b-td>
              <b-td v-if="item.detail.after" variant="primary">
                {{ item.detail.after.currency }} {{ item.detail.after.reserve || '0' }}
              </b-td>
              <b-td v-else variant="primary">
                N/A
              </b-td>
            </b-tr>
            <b-tr>
              <b-td v-if="item.detail.before">
                {{ item.detail.before.currency }} {{ item.detail.before.total_revenue || '0' }}
              </b-td>
              <b-td v-else>
                N/A
              </b-td>
              <b-td v-if="item.detail.before">
                {{ item.detail.before.currency }} {{ item.detail.before.net_income || '0' }}
              </b-td>
              <b-td v-else>
                N/A
              </b-td>
              <b-td v-if="item.detail.before">
                {{ item.detail.before.currency }} {{ item.detail.before.balance || '0' }}
              </b-td>
              <b-td v-else>
                N/A
              </b-td>
              <b-td v-if="item.detail.before">
                {{ item.detail.before.currency }} {{ item.detail.before.settled || '0' }}
              </b-td>
              <b-td v-else>
                N/A
              </b-td>
              <b-td v-if="item.detail.before">
                {{ item.detail.before.currency }} {{ item.detail.before.payout || '0' }}
              </b-td>
              <b-td v-else>
                N/A
              </b-td>
              <b-td v-if="item.detail.before">
                {{ item.detail.before.currency }} {{ item.detail.before.frozen || '0' }}
              </b-td>
              <b-td v-else>
                N/A
              </b-td>
              <b-td v-if="item.detail.before">
                {{ item.detail.before.currency }} {{ item.detail.before.reserve || '0' }}
              </b-td>
              <b-td v-else>
                N/A
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BButton, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormDatepicker, BFormGroup,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import currency from 'currency.js'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useMerchantsJs from './merchants'

const {
  modifyAssetDataManually,
  fetchCommonOptions,
  fetchMerchantAssetsOptLogs,
} = useMerchantsJs()

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormDatepicker,
    BFormGroup,

    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      merchantNo: '',

      totalAmount: [],
      netIncome: [],
      balance: [],
      settlementAmount: [],
      payoutAmount: [],
      frozen: [],
      reserve: [],
      timelineData: [],
      sourceCurrency: [],
      currencyOptions: [],
      verificationCode: '',
      modifiedCurrency: '',
      modified: {
        total: '',
        netIncome: '',
        balance: '',
        settled: '',
        payout: '',
        frozen: '',
        reserve: '',
      },
      reserveType: 0,
      reserveTypeOptions: [
        {
          label: 'Off',
          value: 0,
        },
        {
          label: 'Rolling',
          value: 1,
        },
        {
          label: 'Capacity',
          value: 2,
        },
        {
          label: 'One-Off',
          value: 3,
        },
      ],
      startDate: null,
      endDate: null,
      max: today,
      min: null,
      readonly: true,
    }
  },
  watch: {
    data: {
      handler(assets) {
        if (!assets) {
          return
        }
        this.merchantNo = assets.merchantNo
        // this.timelineData = assets.history
        this.totalAmount = assets.total_amount
        this.netIncome = assets.net_income
        this.balance = assets.balance
        this.settlementAmount = assets.settlement_amount ? assets.settlement_amount : null
        this.payoutAmount = assets.payout ? assets.payout : null
        this.frozen = assets.frozen ? assets.frozen : null
        this.reserve = assets.reserve ? assets.reserve : null
        this.sourceCurrency = assets.currency_list
        this.timelineData = assets.timelines
      },
      deep: true,
    },
    startDate(val) {
      this.min = val
      this.readonly = false
    },
    endDate() {
      this.searchAssets()
    },
  },
  created() {
    fetchCommonOptions(
      options => {
        const { currencies } = options
        currencies.forEach(item => {
          this.currencyOptions.push(item.code)
        })
      },
      fail => {
        showToast(this, 'Warning', `Fetch options fail with ${fail}`)
      },
    )
  },
  methods: {
    getJsonKey(json) {
      const [keyName] = Object.keys(json)
      return keyName
    },
    getJsonValue(json) {
      const keyName = Object.keys(json)
      return currency(json[keyName], {
        separator: ',',
        symbol: '',
      })
        .format()
    },
    modifyAssetDataManually() {
      const assets = {
        merchant_no: this.merchantNo,
        currency: this.modifiedCurrency,
        total_revenue: this.modified.total,
        net_income: this.modified.netIncome,
        balance: this.modified.balance,
        settled: this.modified.settled,
        payout: this.modified.payout,
        frozen: this.modified.frozen,
        reserve: this.modified.reserve,
      }
      const params = {
        verificationCode: this.verificationCode,
        assets,
      }
      modifyAssetDataManually(
        params,
        () => {
          showToast(this, 'Success', 'Modified.')
          this.resetAssetDataManually()
        },
        fail => {
          showToast(this, 'Warning', `Modify manually fail with ${fail}.`)
        },
      )
    },
    resetAssetDataManually() {
      this.modifiedCurrency = ''
      this.modified = {
        total: '',
        netIncome: '',
        balance: '',
        settled: '',
        payout: '',
        frozen: '',
        reserve: '',
      }
    },
    searchAssets() {
      const params = {
        merchantNo: this.merchantNo,
        start: `${this.startDate} 00:00:00`,
        end: `${this.endDate} 23:59:59`,
      }
      fetchMerchantAssetsOptLogs(
        params,
        optLogs => {
          showToast(this, 'Success', 'fetch success.')
          this.timelineData = optLogs
        },
        fail => {
          showToast(this, 'Warning', `Fetch fail with ${fail}.`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
