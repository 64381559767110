<template>
  <div>
    <b-modal
      :visible="isMerchantAssetsModalActive"
      title="Merchant Assets"
      size="xl"
      hide-footer
      centered
      :no-close-on-backdrop="true"
      @change="(val) => isMerchantAssetsModalActive = val"
    >
      <merchant-assets-view :data="merchantAssets" />
    </b-modal>

    <!-- Add New Sidebar -->
    <merchant-list-add-new
      :is-merchant-sidebar-active.sync="isMerchantSidebarActive"
      :data="merchantInfo"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Add New -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button variant="outline-primary" @click="addNewMerchant">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Add New</span>
            </b-button>
            <b-button v-if="$can('write', 'Financials')" class="ml-2" variant="outline-warning" @click="exportMerchantFees">
              <feather-icon icon="SaveIcon" class="mr-50" />
              <span class="align-middle">Export Fees</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        :fields="tableFields"
        :items="merchants"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="merchant_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <!-- Column: User -->
        <template #cell(merchant)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.logo_url"
                :text="data.item.merchant_name"
                variant="primary"
              />
            </template>
            <b-link
              :to="{ name: 'merchant-apps', query: { merchant_no: data.item.merchant_no }, params: { merchant_name: data.item.merchant_name } }"
              class="font-weight-bold d-block"
            >
              {{ data.item.merchant_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.merchant_no }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret dropleft>

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail(data.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'merchant-fee-config', query: { merchant_no: data.item.merchant_no }, params: { merchant_name: data.item.merchant_name } }">
              <feather-icon icon="MapPinIcon" />
              <span class="align-middle ml-50">Fee Config</span>
            </b-dropdown-item>

            <b-dropdown-item @click="syncToV1Confirm(data.item)">
              <feather-icon icon="RepeatIcon" />
              <span class="align-middle ml-50">Sync V1</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showMerchantAssets(data.item.merchant_no)">
              <feather-icon icon="DollarSignIcon" />
              <span class="align-middle ml-50">Assets</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$can('write', 'Merchants.List')" :to="{ name: 'merchant-users', query: { merchant_no: data.item.merchant_no }, params: { merchant_name: data.item.merchant_name } }">
              <feather-icon icon="UsersIcon" />
              <span class="align-middle ml-50">Users</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'payout-account', query: { merchant_no: data.item.merchant_no }, params: { merchant_name: data.item.merchant_name } }">
              <feather-icon icon="AwardIcon" />
              <span class="align-middle ml-50">Payout Accounts</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$can('write', 'VirtualAccount.List')" :to="{ name: 'virtual-account-range', query: { merchant_no: data.item.merchant_no }, params: { merchant_name: data.item.merchant_name } }">
              <feather-icon icon="AwardIcon" />
              <span class="align-middle ml-50">Virtual Accounts</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMerchants"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 mr-3"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BFormInput, BButton, BMedia, BAvatar, BLink,
  BPagination, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { showToast } from '@/libs/tool'
import useMerchantsJs from './merchants'
import MerchantListAddNew from './MerchantListAddNew.vue'
import MerchantAssetsView from './MerchantAssetsView.vue'

const {
  fetchMerchants,
  syncMerchantToV1,
  queryMerchantAssets,
  modifyAssetDataManually,
  exportMerchantFees,
} = useMerchantsJs()

export default {
  components: {
    MerchantListAddNew,
    MerchantAssetsView,

    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      merchants: [],
      dataMeta: {
        from: 1,
        to: 1,
        of: 0,
      },
      perPage: 10,
      tableFields: [
        { key: 'merchant', label: 'MERCHANT' },
        { key: 'location', label: 'LOCATION' },
        { key: 'create_time', label: 'REGISTER DATE' },
        { key: 'status', label: 'STATUS' },
        'actions',
      ],
      currentPage: 1,
      totalMerchants: 0,
      filter: null,
      filterOn: ['merchant_no', 'merchant_name'],

      isMerchantSidebarActive: false,
      merchantInfo: {},

      merchantAssets: null,
      isMerchantAssetsModalActive: false,
      totalAmount: [],
      netIncome: [],
      balance: [],
      settlementAmount: [],
      payoutAmount: [],
      frozen: [],
      reserve: [],
      timelineData: [],

      assetMerchantNo: '',
      targetCurrency: '',
      modifiedTotalAmount: '',
      modifiedNetIncome: '',
      modifiedBalance: '',
      modifiedPayout: '',
      sourceCurrency: '',
    }
  },
  watch: {
    currentPage() {
      this.recalMetaData()
    },
  },
  created() {
    this.getMerchantList()
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    recalMetaData() {
      this.dataMeta.from = this.currentPage * 10 - (10 - 1)
      this.dataMeta.to = this.totalMerchants - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalMerchants
      this.dataMeta.of = this.totalMerchants
    },
    getMerchantList() {
      fetchMerchants(this, merchants => {
        this.merchants = merchants
        this.totalMerchants = merchants.length
        this.recalMetaData()
      })
    },
    resolveStatusVariant(status) {
      if (status === '0') return 'warning'
      if (status === '1') return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === '0') return 'Disabled'
      if (status === '1') return 'Enabled'
      return 'Unknown'
    },
    onFiltered(filteredItems) {
      this.totalMerchants = filteredItems.length
      this.currentPage = 1
    },
    showDetail(merchantDetail) {
      this.merchantInfo = merchantDetail
      this.isMerchantSidebarActive = true
    },
    syncToV1Confirm(merchant) {
      this.$swal({
        title: 'Sync this merchant to v1?',
        text: 'Resync merchant and app info, app already exist will change nothing.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.syncToV1(merchant.merchant_no)
        }
      })
    },
    syncToV1(merchantNo) {
      syncMerchantToV1(
        merchantNo,
        () => {
          this.showAlert('success', 'Done!', 'Sync Success.')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    addNewMerchant() {
      this.isMerchantSidebarActive = true
      this.merchantInfo = {
        merchant_no: '',
      }
    },
    exportMerchantFees() {
      exportMerchantFees(
        response => {
          const { headers } = response
          const contentType = headers['content-type']

          if (response.data) {
            const blob = new Blob([response.data], {
              type: contentType,
            })

            const contentDisposition = headers['content-disposition']
            let fileName = 'unknown'
            if (contentDisposition) {
              fileName = window.decodeURI(headers['content-disposition'].split('=')[1])
            }
            this.downFile(blob, fileName)
          }
        },
        fail => {
          showToast(this, 'Warning', `export fail with ${fail}.`)
        },
      )
    },
    downFile(blob, fileName) {
      if ('download' in document.createElement('a')) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
      } else {
        window.navigator.msSaveBlob(blob, fileName)
      }
    },
    modifyAssetDataManually() {
      const params = {
        merchant_no: this.assetMerchantNo,
        currency: this.targetCurrency,
        total_revenue: this.modifiedTotalAmount,
        net_income: this.modifiedNetIncome,
        balance: this.modifiedBalance,
        payout: this.modifiedPayout,
      }
      modifyAssetDataManually(
        params,
        () => {
          showToast(this, 'Success', 'Modified.')
          this.resetAssetDataManually()
          this.showMerchantAssets(this.assetMerchantNo)
        },
        fail => {
          showToast(this, 'Warning', `Modify manually fail with ${fail}.`)
        },
      )
    },
    resetAssetDataManually() {
      this.targetCurrency = ''
      this.modifiedTotalAmount = ''
      this.modifiedNetIncome = ''
      this.modifiedBalance = ''
      this.modifiedPayout = ''
    },
    showMerchantAssets(merchantNo) {
      queryMerchantAssets(
        merchantNo,
        assets => {
          this.isMerchantAssetsModalActive = true
          setTimeout(() => {
            this.merchantAssets = assets
            this.merchantAssets.merchantNo = merchantNo
          }, 500)
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
